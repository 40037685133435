export const ADMIN_ROUTE = '/admin';
export const HOME_ROUTE = '/';
export const SETTING_ROUTE = '/settings';
export const SUPPORT_ROUTE = '/support';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';

export const ADD_INTERVENTION_FOR_STUDENT_ROUTE =
  '/student/:studentId/intervention/add';
export const EDIT_INTERVENTION_FOR_STUDENT_ROUTE =
  '/student/:studentId/intervention/:interventionId/edit';
export const VIEW_INTERVENTION_FOR_STUDENT_ROUTE =
  '/student/:studentId/intervention/:interventionId/view';

export const ADD_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/behavioralEvent/add';
export const EDIT_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/behavioralEvent/:behavioralEventId/edit';
export const VIEW_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/behavioralEvent/:behavioralEventId/view';

export const ADD_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/studentAssessment/add';
export const EDIT_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/studentAssessment/:studentAssessmentId/edit';
export const VIEW_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE =
  '/student/:studentId/studentAssessment/:studentAssessmentId/view';

export const REQUEST_STUDENT_ACCESS_ROUTE = '/student/:studentId/requestAccess';

export const STUDENTS_ROUTE = '/students';
export const STUDENT_WITH_HISTORY_ROUTE = '/students/:studentId/history';
export const EDIT_STUDENT_DETAILS_ROUTE = '/student/:studentId/edit';
export const VIEW_STUDENT_DETAILS_ROUTE = '/student/:studentId/view';
export const ADD_STUDENT_DETAILS_ROUTE = '/student/add';

// Batch routes
export const ADD_BATCH_INTERVENTIONS_ROUTE = '/interventions/add';
export const ADD_BATCH_BEHAVIORAL_EVENTS_ROUTE = '/behavioralEvents/add';

// unauthenticated routes
// this array contains routes that users can visit and don't need to be told to sign up or sign in on
export const UNAUTHENTICATED_ROUTES = new Set([PRIVACY_POLICY_ROUTE]);
