import { ResourceTypes } from '../constants/resourceTypes';
import { PermissionsDto } from '../api/dtos/permissions.dto';
import { useClientStore } from '../store/store';

import { useMemo } from 'react';

export const useUserHasRolePermissionForSchool = (
  resourceType: ResourceTypes,
  requiredPermissions: Array<keyof PermissionsDto>,
) => {
  const userRolePermissionsForSchool =
    useClientStore.use.userRolePermissionsForSchool();

  const result = useMemo(() => {
    const resourcePermissions =
      userRolePermissionsForSchool.resourceTypePermissions.find(
        (permission) => permission.resourceType === resourceType,
      );

    if (!resourcePermissions) {
      return false;
    }

    return requiredPermissions.some(
      (permission) => resourcePermissions.permissions[permission],
    );
  }, [resourceType, requiredPermissions, userRolePermissionsForSchool]);

  return result;
};
