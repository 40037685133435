import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { StudentForAutocomplete } from '../features/UserPermissions/StudentSearchForAccess.component';

interface SelectedStudentsTableProps {
  selectedStudents: StudentForAutocomplete[];
  onRemoveStudent: (studentId: string) => void;
}

export const SelectedStudentsTable: React.FC<SelectedStudentsTableProps> = ({
  selectedStudents,
  onRemoveStudent,
}) => {
  const getStudentDisplayName = (student: StudentForAutocomplete) => {
    const name = student.name || '';
    return student.studentNumber && student.studentNumber.trim() !== ''
      ? `${name} (${student.studentNumber})`
      : name;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student Name</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedStudents.map((student) => (
            <TableRow key={student.id}>
              <TableCell>{getStudentDisplayName(student)}</TableCell>
              <TableCell>
                <IconButton
                  color="secondary"
                  onClick={() => onRemoveStudent(student.id)}
                  size="medium"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedStudentsTable;
