import React, { useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {
  ADMIN_ROUTE,
  SETTING_ROUTE,
  STUDENTS_ROUTE,
  SUPPORT_ROUTE,
} from '../../constants/routes';
import NavItem from './NavItem.component';
import {
  AdminPanelSettings,
  People,
  Settings,
  Support,
} from '@mui/icons-material';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { useLocation } from 'react-router-dom';
import { useUserHasRolePermissionForSchool } from '../../hooks/useUserHasRolePermissionForSchool';
import { ResourceTypes } from '../../constants/resourceTypes';
import { PERMISSIONS } from '../../constants/permissions';

const containerStyles = {
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  marginTop: '.35rem',
};

// Memoized NavItem for preventing re-renders
const MemoizedNavItem = React.memo(NavItem);

export const NavList: React.FC = () => {
  const viewNavigate = useViewNavigate();
  const location = useLocation();

  const isNavItemActive = useCallback(
    (path: string) => location.pathname.includes(path),
    [location.pathname],
  );

  const hasPermissionManagement = useUserHasRolePermissionForSchool(
    ResourceTypes.PERMISSION_MANAGEMENT,
    [PERMISSIONS.READ, PERMISSIONS.DELETE, PERMISSIONS.EDIT, PERMISSIONS.WRITE],
  );

  const hasUserAccessManagement = useUserHasRolePermissionForSchool(
    ResourceTypes.USER_ACCESS_MANAGEMENT,
    [PERMISSIONS.READ, PERMISSIONS.DELETE, PERMISSIONS.EDIT, PERMISSIONS.WRITE],
  );

  const hasSettingsConfigManagement = useUserHasRolePermissionForSchool(
    ResourceTypes.SETTINGS_CONFIG_MANAGEMENT,
    [PERMISSIONS.READ, PERMISSIONS.DELETE, PERMISSIONS.EDIT, PERMISSIONS.WRITE],
  );

  const showAdminNavItem = useMemo(() => {
    return (
      hasPermissionManagement ||
      hasUserAccessManagement ||
      hasSettingsConfigManagement
    );
  }, [
    hasPermissionManagement,
    hasUserAccessManagement,
    hasSettingsConfigManagement,
  ]);

  return (
    <Box sx={containerStyles}>
      <List>
        <MemoizedNavItem
          name="Students"
          link={STUDENTS_ROUTE}
          icon={<People />}
          onClick={() => viewNavigate(STUDENTS_ROUTE)}
          active={isNavItemActive(STUDENTS_ROUTE)}
        />
        {showAdminNavItem && (
          <MemoizedNavItem
            name="Admin"
            link={ADMIN_ROUTE}
            icon={<AdminPanelSettings />}
            onClick={() => viewNavigate(ADMIN_ROUTE)}
            active={isNavItemActive(ADMIN_ROUTE)}
          />
        )}
      </List>

      <div>
        <Divider />
        <List>
          <MemoizedNavItem
            name="Settings"
            link={SETTING_ROUTE}
            icon={<Settings />}
            onClick={() => viewNavigate(SETTING_ROUTE)}
            active={isNavItemActive(SETTING_ROUTE)}
          />
          <MemoizedNavItem
            name="Support"
            link={SUPPORT_ROUTE}
            icon={<Support />}
            onClick={() => viewNavigate(SUPPORT_ROUTE)}
            active={isNavItemActive(SUPPORT_ROUTE)}
          />
        </List>
      </div>
    </Box>
  );
};
