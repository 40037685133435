import { Save as SaveIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useEventCallback,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useGetPossibleValuesFromEntityConfig } from '../../store/entityConfigHelpers';
import { useClientStore } from '../../store/store';
import { BehavioralEventDto } from '../../api/dtos/behavioralEvent.dto';
import { UserDto } from '../../api/dtos/user.dto';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { DateUtilites } from '../../lib/intellitierDayJs';
import { useGetEntityConfigForSchoolId } from '../../api/services/entityConfig.service';
import { useBatchAddBehavioralEvent } from '../../api/services/schools.service';
import AutocompleteFormField from '../../components/AutocompleteFormField';
import BackOrCancelFormButton from '../../components/BackOrCancelFormButton';
import { StudentForAutocomplete } from '../../components/StudentSearch.component';
import TextFormField from '../../components/TextFormField';
import DateTimeFormField from '../../components/DateTimeFormField'; // Import the new DateTimeFormField

const defaultUser: UserDto = {
  userId: '',
  name: '',
  email: '',
};

export const BatchAddBehavioralEventsForm: React.FC<{
  selectedStudentsFromAutocomplete: StudentForAutocomplete[];
}> = ({ selectedStudentsFromAutocomplete }) => {
  const todaysDateAndTime = DateUtilites.createDateInLocalDateTime();
  const viewNavigate = useViewNavigate();
  const selectedSchoolId = useClientStore.use.selectedSchoolId();

  // Fetch necessary data
  useGetEntityConfigForSchoolId(selectedSchoolId);

  const antecedentOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'antecedent',
  );
  const behaviorOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'behavior',
  );
  const intensityOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'intensity',
  );
  const durationOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'duration',
  );
  const consequenceOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'consequence',
  );

  const defaultValues: BehavioralEventDto = useMemo(
    () => ({
      behavioralEventId: '',
      globalBehavioralEventId: '',
      eventDate: DateUtilites.createDateInLocalDateTime(),
      antecedent: [],
      behavior: [],
      intensity: '',
      duration: '',
      consequence: [],
      suspectedFunction: '',
      location: '',
      additionalDetails: '',
      associatedStudents: [],
      othersInvolved: [],
      createdByUser: { ...defaultUser },
      updatedByUser: { ...defaultUser },
    }),
    [],
  );

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'othersInvolved',
  });

  const { mutate: batchAddBehavioralEvent, isPending } =
    useBatchAddBehavioralEvent(selectedSchoolId, () =>
      viewNavigate(`/students`),
    );

  const onSubmit = useEventCallback((behavioralEvent: BehavioralEventDto) => {
    const studentIds = selectedStudentsFromAutocomplete.map(
      (student) => student.id,
    );
    if (studentIds.length === 0) {
      // TODO show error to user
      return;
    }
    batchAddBehavioralEvent({ studentIds, behavioralEvent });
  });

  const getSaveOrUpdateButtonText = () => {
    return isPending ? 'Saving...' : 'Save';
  };

  return (
    <Card elevation={3} sx={{ padding: 1, marginTop: 4 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={'Add New Social-Emotional and Behavior Event'} />
        <CardContent>
          <DateTimeFormField
            name="eventDate"
            control={control}
            label="Date and Time of Event"
            defaultValue={todaysDateAndTime}
          />

          <AutocompleteFormField
            name="antecedent"
            label="Antecedents"
            options={antecedentOptions}
            control={control}
            multiple
          />

          <AutocompleteFormField
            name="behavior"
            label="Behaviors"
            options={behaviorOptions}
            control={control}
            multiple
          />

          <AutocompleteFormField
            name="intensity"
            label="Intensity"
            options={intensityOptions}
            control={control}
          />

          <AutocompleteFormField
            name="duration"
            label="Duration"
            options={durationOptions}
            control={control}
          />

          <AutocompleteFormField
            name="consequence"
            label="Consequences"
            options={consequenceOptions}
            control={control}
            multiple
          />

          <TextFormField
            name="suspectedFunction"
            label="Suspected Function"
            control={control}
            multiline
          />
          <TextFormField name="location" label="Location" control={control} />

          <TextFormField
            name="additionalDetails"
            label="Additional Details"
            control={control}
            multiline
          />

          {/* Handling dynamic form fields for 'othersInvolved' */}
          {fields.length > 0 ? (
            <Box
              sx={{ border: 1, borderColor: 'primary.main', marginTop: '1rem' }}
              padding={'1rem'}
            >
              {fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <Typography variant="subtitle1">
                      {`External Person ${index + 1}`}
                    </Typography>
                    <Button onClick={() => remove(index)}>Remove Person</Button>
                  </Box>
                  <TextFormField
                    name={`othersInvolved.${index}.name`}
                    label="Name"
                    control={control}
                  />
                  <TextFormField
                    name={`othersInvolved.${index}.role`}
                    label="Role"
                    control={control}
                  />
                  {index < fields.length - 1 && <Divider sx={{ my: 2 }} />}
                </React.Fragment>
              ))}
              {/* Add Person button inside the blue box */}
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginTop: '1rem' }}
                onClick={() => append({ name: '', role: '' })}
              >
                Add Person
              </Button>
            </Box>
          ) : (
            // Add Person button outside the blue box if no persons are added
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: '1rem' }}
              onClick={() => append({ name: '', role: '' })}
            >
              Add Person
            </Button>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', gap: '1rem' }}>
          <BackOrCancelFormButton mode={'add'} />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={isPending}
          >
            {getSaveOrUpdateButtonText()}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
