import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Drawer,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid2 as Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavList } from './NavList.component';
import { SignInOrOutButton } from './SignInOrOutButton.component';
import { SchoolSelect } from './SchoolSelect.component';
import { useIsAuthenticated } from '@azure/msal-react';
import { DRAWER_WIDTH } from './constants';
import {
  usePrefechGetUserWithSchools,
  usePrefetchGetUserByToken,
} from '../../api/services/user.service';
import { SignUpButton } from './SignUpButton.component';
import { useLocation } from 'react-router-dom';

const APP_BAR_HEIGHT = '64px';

const AppShell = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isResponsiveDrawerOpen, updateIsResponsiveDrawerOpen] =
    useState(false);

  usePrefechGetUserWithSchools();
  usePrefetchGetUserByToken();

  const handleDrawerToggle = () => {
    updateIsResponsiveDrawerOpen(!isResponsiveDrawerOpen);
  };

  const drawerContent = useMemo(
    () => (
      <>
        <Toolbar />
        <NavList />
      </>
    ),
    [],
  );

  // Close drawer on navigation for small screens
  useEffect(() => {
    if (isSmallScreen) {
      updateIsResponsiveDrawerOpen(false);
    }
  }, [location, isSmallScreen]);

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CustomAppBar
        isMobileOpen={isResponsiveDrawerOpen}
        isSmallScreen={isSmallScreen}
        isAuthenticated={isAuthenticated}
        onMenuClick={handleDrawerToggle}
      />
      <ResponsiveDrawer
        isSmallScreen={isSmallScreen}
        open={isResponsiveDrawerOpen}
        onClose={handleDrawerToggle}
        content={drawerContent}
      />
      <MainContent>{children}</MainContent>
    </Box>
  );
};

export default AppShell;

const CustomAppBar = ({
  isSmallScreen,
  isAuthenticated,
  onMenuClick,
  isMobileOpen,
}: {
  isSmallScreen: boolean;
  isAuthenticated: boolean;
  onMenuClick: () => void;
  isMobileOpen: boolean;
}) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}
      role="banner"
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: APP_BAR_HEIGHT,
        }}
      >
        {isSmallScreen && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onMenuClick}
            sx={{ mr: 2 }}
            aria-label="Open navigation menu"
            aria-expanded={isMobileOpen}
            aria-controls="navigation-menu"
          >
            <MenuIcon />
          </IconButton>
        )}
        {!isSmallScreen && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 0,
            }}
          >
            <Logo />
          </Box>
        )}
        {isAuthenticated && <SchoolSelect />}
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-end"
          wrap="nowrap"
        >
          <SignUpButton />
          <SignInOrOutButton />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const ResponsiveDrawer = ({
  isSmallScreen,
  open,
  onClose,
  content,
}: {
  isSmallScreen: boolean;
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Drawer
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      open={isSmallScreen ? open : true}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      aria-label="Navigation menu"
      sx={{
        width: isSmallScreen ? 'auto' : DRAWER_WIDTH,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [`& .MuiDrawer-paper`]: {
          width: isSmallScreen ? 'auto' : DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
    >
      {content}
    </Drawer>
  );
};

const MainContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      component="main"
      role="main"
      sx={{
        flexGrow: 1,
        overflow: 'inherit',
        mt: APP_BAR_HEIGHT, // Matches AppBar height
      }}
    >
      {children}
    </Box>
  );
};

const Logo = () => {
  const theme = useTheme();
  return (
    <img
      src="/intellitier.svg"
      alt="IntelliTier logo"
      style={{
        marginRight: theme.spacing(2),
        height: '2rem',
        width: 'auto',
      }}
    />
  );
};
