import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useClientStore } from '../store/store';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
export const intellierDayJs = dayjs;

export class DateUtilites {
  static setDefaultTimezone(timezone: string) {
    intellierDayJs.tz.setDefault(timezone);
  }

  static createDateInLocalDateTime() {
    const currentTimezone =
      useClientStore.getState().selectedSchool?.timezoneId;
    return intellierDayJs().tz(currentTimezone);
  }

  static formatDateToLocalDateTime(date: Dayjs) {
    const currentTimezone =
      useClientStore.getState().selectedSchool?.timezoneId;
    return intellierDayJs(date).tz(currentTimezone).format('L LT');
  }

  static createLocalTimeFromUtcDate(utcDate: string) {
    const currentTimezone =
      useClientStore.getState().selectedSchool?.timezoneId;
    return intellierDayJs.utc(utcDate).tz(currentTimezone);
  }

  static formatIntoIsoString(dateString: string) {
    const currentTimezone =
      useClientStore.getState().selectedSchool?.timezoneId;
    return intellierDayJs(dateString).tz(currentTimezone, true).toISOString();
  }
}
