import { SxProps, Theme, Grid2 as Grid } from '@mui/material';

interface PageContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  spacing?: number;
}

export const ResponsivePageContainer: React.FC<PageContainerProps> = ({
  children,
  sx = {
    p: 3,
  },
  spacing = 2,
}) => {
  return (
    <Grid sx={sx} container justifyContent="center" spacing={spacing}>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 10,
          lg: 8,
          xl: 6,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
