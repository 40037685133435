// reference for readme later:
// https://codewithstyle.info/typescript-dto/
// https://dev.to/tamj0rd2/dto-a-typescript-utility-type-4o3m
// https://ezpzdev.medium.com/data-transfer-objects-dtos-a-comprehensive-guide-2d00e8fa2ec3
// https://github.com/rsinger86/dto-classes

// Not sure if we want to make this for each one yet, but it makes typing the response easier
import dayjs, { Dayjs } from 'dayjs';

export interface StudentEntity {
  studentId: string;
  studentNumber: string;
  name: string;
  grade: string;
  dateOfBirth?: string;
  additionalInfo: string;
  updatedBy: string;
  dateUpdated: string;
  externalFactors: string[];
  healthFactors: string[];
  studentGoals: string[];
}

export interface StudentDto {
  studentId?: string;
  studentNumber: string;
  name: string;
  grade: string;
  dateOfBirth: Dayjs | null;
  additionalInfo: string;
  externalFactors: string[];
  healthFactors: string[];
  studentGoals: string[];
  updatedBy: string;
  dateUpdated: Dayjs;
}

export class StudentDto {
  constructor(studentEntity: StudentEntity) {
    this.studentId = studentEntity.studentId;
    this.studentNumber = studentEntity.studentNumber;
    this.name = studentEntity.name;
    this.grade = studentEntity.grade;
    this.additionalInfo = studentEntity.additionalInfo;
    this.updatedBy = studentEntity.updatedBy;
    this.dateOfBirth = studentEntity.dateOfBirth
      ? dayjs(studentEntity.dateOfBirth)
      : null;
    this.dateUpdated = dayjs(studentEntity.dateUpdated);
    this.externalFactors = studentEntity.externalFactors;
    this.healthFactors = studentEntity.healthFactors;
    this.studentGoals = studentEntity.studentGoals;
  }

  static createDtoFromEntity(studentEntity: StudentEntity): StudentDto {
    return new StudentDto(studentEntity);
  }
}
