import { useMsal } from '@azure/msal-react';
import { useEventCallback } from '@mui/material';
import { useState } from 'react';
import { BrowserUtils } from '@azure/msal-browser';
import { loginRequest } from '../../../constants/authConfig';
import { ReactTagManager } from 'react-gtm-ts';

export const useAuthActions = () => {
  const { instance } = useMsal();
  const [isSubmitting, setIsSubmitting] = useState({
    login: false,
    signup: false,
    logout: false,
  });

  const onSignupSubmit = useEventCallback(async () => {
    setIsSubmitting((prev) => ({ ...prev, signup: true }));
    ReactTagManager.action({ event: 'signup' });

    try {
      await instance.loginRedirect(loginRequest);
      ReactTagManager.action({
        event: 'signup-success',
      });
    } catch (error) {
      ReactTagManager.action({
        event: 'signup-error',
        error: error instanceof Error ? error.message : 'Unknown error',
      });
    } finally {
      setIsSubmitting((prev) => ({ ...prev, signup: false }));
    }
  });

  const onLoginSubmit = useEventCallback(async () => {
    setIsSubmitting((prev) => ({ ...prev, login: true }));
    ReactTagManager.action({ event: 'login' });
    try {
      await instance.loginRedirect(loginRequest);
      ReactTagManager.action({
        event: 'login-success',
      });
    } catch (error) {
      ReactTagManager.action({
        event: 'login-error',
        error: error instanceof Error ? error.message : 'Unknown error',
      });
    } finally {
      setIsSubmitting((prev) => ({ ...prev, login: false }));
    }
  });

  const onLogoutSubmit = useEventCallback(() => {
    setIsSubmitting((prev) => ({ ...prev, logout: true }));
    ReactTagManager.action({ event: 'logout' });

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
    setIsSubmitting((prev) => ({ ...prev, logout: false }));
  });

  return {
    onSignupSubmit,
    onLoginSubmit,
    onLogoutSubmit,
    isSubmitting,
  };
};
