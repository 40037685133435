import { create } from 'zustand';
import { createThemeSlice, ThemeSlice } from './slices/themeSlice';
import { createSchoolSlice, SchoolSlice } from './slices/schoolSlice';
import { createUserSlice, UserSlice } from './slices/userSlice';
import { createStudentSlice, StudentSlice } from './slices/studentSlice';

import { createSelectors } from './createSelectorHelper';

type ClientState = ThemeSlice & SchoolSlice & UserSlice & StudentSlice;

export const useClientStoreBase = create<ClientState>()((set, get, api) => ({
  ...createThemeSlice(set, get, api),
  ...createSchoolSlice(set, get, api),
  ...createUserSlice(set, get, api),
  ...createStudentSlice(set, get, api),
}));
export const useClientStore = createSelectors(useClientStoreBase);
