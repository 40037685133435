import { EntityConfigDto, EntityDtoName } from '../api/dtos/entityConfig.dto';
import { useClientStore } from './store';

export function useGetEntityConfigFromMap(
  entityDtoName: EntityDtoName,
): EntityConfigDto | undefined {
  const entityConfigMap = useClientStore.use.entityConfigMap();
  const entityConfig = entityConfigMap.get(entityDtoName);

  return entityConfig;
}

export function useGetPossibleValuesFromEntityConfig(
  entityDtoName: EntityDtoName,
  fieldName: string,
): string[] {
  const entityConfigMap = useClientStore.use.entityConfigMap();
  const entityConfig = entityConfigMap.get(entityDtoName);

  return entityConfig?.getPossibleValues(fieldName) ?? [];
}
