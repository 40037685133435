export interface RoleEntity {
  roleName: string;
  systemRole: boolean;
}

export interface RoleDto {
  roleName: string;
  systemRole: boolean;
}

export class RoleDto {
  constructor(roleEntity: RoleEntity) {
    this.roleName = roleEntity.roleName;
    this.systemRole = roleEntity.systemRole;
  }

  static createDtoFromEntity(roleEntity: RoleEntity): RoleDto {
    return new RoleDto(roleEntity);
  }
}
