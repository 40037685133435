import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  UserWithSchoolsDto,
  UserWithSchoolsEntity,
} from '../dtos/userWithSchools.dto';
import intellitierAxios from '../../lib/intellitierAxios';
import { UserDto, UserEntity } from '../dtos/user.dto';

const fetchUserWithSchools = async () => {
  const { data } = await intellitierAxios.get<UserWithSchoolsDto>(
    `/users/userWithSchools`,
    {
      transformResponse: [
        (data) => {
          const userWithSchoolsEntity: UserWithSchoolsEntity = JSON.parse(data);
          const userWithSchoolsDto = UserWithSchoolsDto.createDtoFromEntity(
            userWithSchoolsEntity,
          );

          userWithSchoolsDto.schools.sort((a, b) =>
            a.schoolName.localeCompare(b.schoolName),
          );

          return userWithSchoolsDto;
        },
      ],
    },
  );

  return data;
};

const fetchUserByToken = async () => {
  const { data } = await intellitierAxios.get<UserDto>(`/users/user`, {
    transformResponse: [
      (data) => {
        const userEntity: UserEntity = JSON.parse(data);
        const userInfo = UserDto.createDtoFromEntity(userEntity);
        return userInfo;
      },
    ],
  });

  return data;
};

export function useGetUserByToken() {
  return useQuery({
    queryKey: ['userByToken'],
    queryFn: fetchUserByToken,
  });
}

export const usePrefetchGetUserByToken = async () => {
  // TODO: fix lint issue
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: ['userByToken'],
    queryFn: () => fetchUserByToken(),
  });
};

export const usePrefechGetUserWithSchools = async () => {
  // TODO: fix lint issue
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();

  await queryClient.prefetchQuery({
    queryKey: ['userWithSchools'],
    queryFn: () => fetchUserWithSchools(),
  });
};

export function useGetUserWithSchools() {
  return useQuery({
    queryKey: ['userWithSchools'],
    queryFn: fetchUserWithSchools,
  });
}

export function useUpdateTourCompletionStatus(
  userId: string,
  onSuccessCb?: (userWithSchools: UserWithSchoolsDto) => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (hasCompletedStudentsOnboardingTour: boolean) => {
      return intellitierAxios.post<UserWithSchoolsDto>(
        `/users/${userId}/hasCompletedStudentsOnboardingTour`,
        {
          hasCompletedStudentsOnboardingTour,
        },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['userWithSchools'],
      });
      onSuccessCb && onSuccessCb(data.data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
