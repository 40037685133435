import { Save as SaveIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  useEventCallback,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetPossibleValuesFromEntityConfig } from '../../store/entityConfigHelpers';
import { useClientStore } from '../../store/store';
import { InterventionDto } from '../../api/dtos/intervention.dto';
import { UserDto } from '../../api/dtos/user.dto';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { useGetEntityConfigForSchoolId } from '../../api/services/entityConfig.service';
import { useGetInterventionById } from '../../api/services/intervention.service';
import { useBatchAddIntervention } from '../../api/services/schools.service';
import { CenteredPageCircularProgress } from '../../components/CenteredPageCircularProgress';
import AutocompleteFormField from '../../components/AutocompleteFormField';
import BackOrCancelFormButton from '../../components/BackOrCancelFormButton';
import { StudentForAutocomplete } from '../../components/StudentSearch.component';
import DateTimeFormField from '../../components/DateTimeFormField';
import { DateUtilites } from '../../lib/intellitierDayJs';

const defaultUser: UserDto = {
  userId: '',
  name: '',
  email: '',
};

export const BatchAddInterventionForm: React.FC<{
  selectedStudentsFromAutocomplete: StudentForAutocomplete[];
}> = ({ selectedStudentsFromAutocomplete }) => {
  const todaysDate = DateUtilites.createDateInLocalDateTime();
  const viewNavigate = useViewNavigate();
  const { studentId = '', interventionId = '' } = useParams();
  const selectedSchoolId = useClientStore.use.selectedSchoolId();

  useGetEntityConfigForSchoolId(selectedSchoolId);

  const interventionTierOptions = useGetPossibleValuesFromEntityConfig(
    'InterventionDTO',
    'tier',
  );

  const interventionStateOptions = useGetPossibleValuesFromEntityConfig(
    'InterventionDTO',
    'interventionState',
  );

  const progressRatingOptions = useGetPossibleValuesFromEntityConfig(
    'InterventionNoteDTO',
    'progressRating',
  );

  const interventionTypeOptions = useGetPossibleValuesFromEntityConfig(
    'InterventionDTO',
    'interventionType',
  );

  const defaultValues: InterventionDto = useMemo(
    () => ({
      interventionId: '',
      interventionType: [],
      tier: '',
      description: '',
      interventionStartDate: todaysDate,
      interventionEndDate: todaysDate,
      interventionState: '',
      interventionNotes: [
        {
          interventionNoteId: '',
          description: '',
          progressRating: '',
          createdBy: '',
          createdByUser: defaultUser,
          dateCreated: DateUtilites.createDateInLocalDateTime(),
          updatedBy: '',
          updatedByUser: defaultUser,
          dateUpdated: DateUtilites.createDateInLocalDateTime(),
        },
      ],
      createdByUser: { ...defaultUser },
      updatedByUser: { ...defaultUser },
    }),
    [todaysDate],
  );

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'interventionNotes',
  });

  const {
    data: interventionData,
    isSuccess,
    isLoading,
  } = useGetInterventionById(studentId, selectedSchoolId, interventionId);

  useEffect(() => {
    if (isSuccess && interventionData) {
      reset(interventionData);
    }
  }, [interventionData, isSuccess, reset]);

  const { mutate: createOrUpdateIntervention, isPending } =
    useBatchAddIntervention(selectedSchoolId, () => viewNavigate(`/students`));

  const onSubmit = useEventCallback((intervention: InterventionDto) => {
    const studentIds = selectedStudentsFromAutocomplete.map(
      (student) => student.id,
    );

    if (studentIds.length === 0) {
      // TODO - show error to user
      return;
    }
    createOrUpdateIntervention({ studentIds, intervention });
  });

  const getSaveOrUpdateButtonText = () => {
    return isPending ? 'Saving...' : 'Save';
  };

  if (isLoading) {
    return <CenteredPageCircularProgress withTopMargin={false} />;
  }

  return (
    <Card elevation={3} sx={{ padding: 2, marginTop: 6 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Add New Intervention" />
        <CardContent>
          <AutocompleteFormField
            name="interventionType"
            label="Intervention Types"
            options={interventionTypeOptions}
            control={control}
            multiple
          />

          <AutocompleteFormField
            name="tier"
            label="Tier"
            options={interventionTierOptions}
            control={control}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            )}
          />

          <DateTimeFormField
            name="interventionStartDate"
            control={control}
            label="Intervention Start Date & Time"
            defaultValue={todaysDate}
          />

          <Controller
            name="interventionEndDate"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                defaultValue={todaysDate}
                label="Intervention End Date & Time"
                {...field}
                sx={{ marginTop: '.5rem' }}
              />
            )}
          />

          <AutocompleteFormField
            name="interventionState"
            label="Intervention State"
            options={interventionStateOptions}
            control={control}
          />

          {fields.length > 0 ? (
            <Box
              sx={{ border: 1, borderColor: 'primary.main', marginTop: '1rem' }}
              padding={'1rem'}
            >
              {fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <Typography variant="subtitle1">
                      {`Intervention Note ${index + 1}`}
                    </Typography>
                    <Button onClick={() => remove(index)}>Remove Note</Button>
                  </Box>
                  <TextField
                    {...register(`interventionNotes.${index}.description`)}
                    label="Details"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={3}
                  />

                  <AutocompleteFormField
                    name={`interventionNotes.${index}.progressRating`}
                    label="Progress Rating"
                    options={progressRatingOptions}
                    control={control}
                  />

                  {item.createdByUser?.name && (
                    <Typography
                      variant="body2"
                      sx={{ marginTop: '1rem' }}
                      color="info.main"
                    >
                      Created by: {item.createdByUser.name} on{' '}
                      {item.formattedDateCreated}
                    </Typography>
                  )}

                  {item.updatedByUser?.name && (
                    <Typography variant="body2" color="info.main">
                      Last updated by: {item.updatedByUser.name} on{' '}
                      {item.formattedDateUpdated}
                    </Typography>
                  )}
                  {index !== fields.length - 1 && (
                    <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
                  )}
                </React.Fragment>
              ))}
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginTop: '2rem' }}
                onClick={() =>
                  append({
                    interventionNoteId: '',
                    description: '',
                    progressRating: '',
                    createdBy: '',
                    dateCreated: dayjs(),
                    updatedBy: '',
                    dateUpdated: dayjs(),
                  })
                }
              >
                Add Note
              </Button>
            </Box>
          ) : (
            // Render the "Add Note" button even if there are no existing notes
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: '2rem' }}
              onClick={() =>
                append({
                  interventionNoteId: '',
                  description: '',
                  progressRating: '',
                  createdBy: '',
                  dateCreated: dayjs(),
                  updatedBy: '',
                  dateUpdated: dayjs(),
                })
              }
            >
              Add Note
            </Button>
          )}
          {interventionData?.createdByUser?.name && (
            <Typography
              variant="body2"
              sx={{ marginTop: '1rem' }}
              color="info.main"
            >
              Created by: {interventionData.createdByUser.name} on{' '}
              {interventionData.formattedDateCreated}
            </Typography>
          )}
          {interventionData?.updatedByUser?.name && (
            <Typography variant="body2" color="info.main">
              Last updated by: {interventionData.updatedByUser.name} on{' '}
              {interventionData.formattedDateUpdated}
            </Typography>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <BackOrCancelFormButton mode={'add'} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={isPending}
          >
            {getSaveOrUpdateButtonText()}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
