import { Autocomplete, TextField } from '@mui/material';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';

interface AutocompleteFormFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: string[];
  control: Control<T>;
  disabled?: boolean;
  multiple?: boolean;
}

const AutocompleteFormField = <T extends FieldValues>({
  name,
  label,
  options,
  control,
  disabled = false,
  multiple = false,
}: AutocompleteFormFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value = multiple ? [] : null },
        fieldState: { error },
      }) => (
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          value={value}
          onChange={(event, newValue) => onChange(newValue)}
          options={options}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
              margin="normal"
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteFormField;
