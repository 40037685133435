import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { DateUtilites } from '../lib/intellitierDayJs';

interface DateTimeFormFieldProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  label: string;
  defaultValue?: dayjs.Dayjs;
  disabled?: boolean;
}

const DateTimeFormField = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  defaultValue = DateUtilites.createDateInLocalDateTime(),
  disabled = false,
}: DateTimeFormFieldProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DateTimePicker
          {...field}
          label={label}
          defaultValue={defaultValue}
          disabled={disabled}
          sx={{ marginTop: '1rem', marginRight: '.5rem' }}
        />
      )}
    />
  );
};

export default DateTimeFormField;
