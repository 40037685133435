import { StateCreator } from 'zustand';
import { StudentWithHistoryExpandedState } from '../../pages/StudentWithHistory.page';

export interface StudentSlice {
  studentWithHistoryExpandedState: StudentWithHistoryExpandedState;
  setStudentWithHistoryExpandedState: (
    expandedState: StudentWithHistoryExpandedState,
  ) => void;
}

export const createStudentSlice: StateCreator<StudentSlice> = (set) => ({
  studentWithHistoryExpandedState: {
    behavioralEvents: false,
    interventions: false,
    studentAccessmentDocuments: false,
  },
  setStudentWithHistoryExpandedState: (studentWithHistoryExpandedState) =>
    set(() => ({ studentWithHistoryExpandedState })),
});
