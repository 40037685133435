import { StateCreator } from 'zustand';
import {
  EntityConfigDto,
  EntityDtoName,
} from '../../api/dtos/entityConfig.dto';
import { DateUtilites } from '../../lib/intellitierDayJs';
import { SchoolDto } from '../../api/dtos/school.dto';

export interface SchoolSlice {
  entityConfigMap: Map<EntityDtoName, EntityConfigDto>;
  selectedSchoolId: string;
  updateSelectedSchoolId: (selectedSchoolId: string) => void;

  selectedSchool: SchoolDto | undefined;
  updateSelectedSchool: (selectedSchool: SchoolDto) => void;
}

export const createSchoolSlice: StateCreator<SchoolSlice> = (
  set,
  get,
  api,
) => ({
  entityConfigMap: new Map<EntityDtoName, EntityConfigDto>(),

  selectedSchoolId: '',
  updateSelectedSchoolId: (selectedSchoolId) => {
    set({ selectedSchoolId });
  },

  selectedSchool: undefined,
  updateSelectedSchool: (selectedSchool) => {
    if (selectedSchool) {
      DateUtilites.setDefaultTimezone(selectedSchool.timezoneId); // note the school dto defaults to PST if a school doesnt have a timezone
    }
    set({ selectedSchool });
  },
});
