export interface FieldConfigEntity {
  fieldName: string;
  friendlyName?: string;
  visible: boolean;
  possibleValues: string[];
}

export type EntityDtoName =
  | 'StudentDTO'
  | 'InterventionNoteDTO'
  | 'InterventionDTO'
  | 'BehavioralEventDTO'
  | 'StudentAssessmentDTO';

export const EntityDTOFriendlyNameMap = new Map<string, string>([
  ['BehavioralEventDTO', 'Social-Emotional and Behavior Event'],
  ['InterventionDTO', 'Intervention'],
  ['InterventionNoteDTO', 'Intervention Note'],
  ['StudentDTO', 'Student'],
  ['StudentAssessmentDTO', 'Student Assessment'],
]);

export const FieldsConfigsFriendlyNameMap = new Map<string, string>([
  ['antecedent', 'Antecedent'],
  ['behavior', 'Behavior'],
  ['consequence', 'Consequence'],
  ['duration', 'Duration'],
  ['intensity', 'Intensity'],
  ['severity', 'Severity'],
  ['interventionState', 'Intervention State'],
  ['interventionType', 'Intervention Type'],
  ['tier', 'Tier'],
  ['type', 'Type'],
  ['progressRating', 'Progress Rating'],
  ['grade', 'Grade'],
  ['assessmentType', 'Assessment Type'],
]);

export interface EntityConfigEntity {
  entityName: EntityDtoName;
  fieldsConfigs: FieldConfigEntity[];
}

export interface EntityConfigDto {
  entityName: EntityDtoName;
  fieldsConfigs: FieldConfigEntity[]; // todo update to be a dto object
  fieldsConfigsMap: Map<string, FieldConfigEntity>;
  entityDTOFriendlyNameMap: Map<string, string>;
  fieldsConfigFriendlyNameMap: Map<string, string>;
}

export class EntityConfigDto {
  constructor(entityConfigEntity: EntityConfigEntity) {
    this.entityName = entityConfigEntity.entityName;
    this.fieldsConfigs = [];
    this.fieldsConfigsMap = new Map<string, FieldConfigEntity>();
    this.entityDTOFriendlyNameMap = EntityDTOFriendlyNameMap;
    this.fieldsConfigFriendlyNameMap = FieldsConfigsFriendlyNameMap;

    entityConfigEntity.fieldsConfigs.forEach((fieldConfig) => {
      const fieldConfigEntity: FieldConfigEntity = {
        fieldName: fieldConfig.fieldName,
        friendlyName: this.getUserFriendlyFieldName(fieldConfig.fieldName),
        possibleValues: fieldConfig.possibleValues || [],
        visible: fieldConfig.visible,
      };
      this.fieldsConfigs.push(fieldConfigEntity);
      this.fieldsConfigsMap.set(fieldConfig.fieldName, fieldConfigEntity);
    });
  }

  getUserFriendlyEntityName(): string {
    return (
      this.entityDTOFriendlyNameMap.get(this.entityName) ??
      this.entityName.replace('DTO', '')
    );
  }

  getUserFriendlyFieldName(fieldName: string): string {
    return this.fieldsConfigFriendlyNameMap.get(fieldName) ?? fieldName;
  }

  getPossibleValues(fieldName: string): string[] {
    return this.fieldsConfigsMap.get(fieldName)?.possibleValues ?? [];
  }

  getFieldConfigKeysArry(): FieldConfigEntity[] {
    return Array.from(this.fieldsConfigsMap.values());
  }

  static createDtoFromEntity(
    entityConfigEntity: EntityConfigEntity,
  ): EntityConfigDto {
    return new EntityConfigDto(entityConfigEntity);
  }
}
