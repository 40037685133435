export const SUPPORTED_TIMEZONE_VALUES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu',
] as const;

export type SupportedTimezoneValues =
  (typeof SUPPORTED_TIMEZONE_VALUES)[number];

export const SUPPORTED_TIMEZONES: {
  value: SupportedTimezoneValues;
  display: string;
}[] = [
  { value: 'America/New_York', display: 'New York (Eastern)' }, // Eastern Time Zone; uses both EST and EDT
  { value: 'America/Chicago', display: 'Chicago (Central)' }, // Central Time Zone; uses both CST and CDT
  { value: 'America/Denver', display: 'Denver (Mountain)' }, // Mountain Time Zone; uses both MST and MDT
  {
    value: 'America/Phoenix',
    display: 'Phoenix (Mountain, no Daylight Savings)',
  }, // Mountain Time Zone (no DST)
  { value: 'America/Los_Angeles', display: 'Los Angeles (Pacific)' }, // Pacific Time Zone; uses both PST and PDT
  { value: 'America/Anchorage', display: 'Anchorage (Alaska)' }, // Alaska Time Zone; uses both AKST and AKDT
  { value: 'Pacific/Honolulu', display: 'Honolulu (Hawaii-Aleutian)' }, // Hawaii-Aleutian Time Zone; uses both HST and HDT
];
