export interface UserEntity {
  userId: string;
  email: string;
  name: string;
}

export interface UserDto {
  userId: string;
  email: string;
  name: string;
}
export class UserDto {
  constructor(userEntity: UserEntity) {
    this.userId = userEntity.userId;
    this.email = userEntity.email;
    this.name = userEntity.name;
  }

  static createDtoFromEntity(userEntity: UserEntity): UserDto {
    return new UserDto(userEntity);
  }
}
