import {
  DetailedUserStudentPermissionsDto,
  DetailedUserStudentPermissionsEntity,
} from './detailedUserStudentPermissions.dto';
import {
  RolePermissionsDto,
  RolePermissionsEntity,
} from './rolePermission.dto';

export interface DetailedUserPermissionsEntity {
  rolePermissions: RolePermissionsEntity[];
  studentPermissions: DetailedUserStudentPermissionsEntity[];
}

export interface DetailedUserPermissionsDto {
  rolePermissions: RolePermissionsDto[];
  studentPermissions: DetailedUserStudentPermissionsDto[];
}

export class DetailedUserPermissionsDto {
  constructor(detailedUserPermissionsEntity: DetailedUserPermissionsEntity) {
    this.rolePermissions = detailedUserPermissionsEntity.rolePermissions.map(
      (rolePermission) => new RolePermissionsDto(rolePermission),
    );
    this.studentPermissions =
      detailedUserPermissionsEntity.studentPermissions.map(
        (studentPermission) =>
          DetailedUserStudentPermissionsDto.createDtoFromEntity(
            studentPermission,
          ),
      );
  }

  getRoleNames(): string[] {
    return this.rolePermissions.map(
      (rolePermission) => rolePermission.role.roleName,
    );
  }

  static createDtoFromEntity(
    detailedUserPermissionsEntity: DetailedUserPermissionsEntity,
  ): DetailedUserPermissionsDto {
    return new DetailedUserPermissionsDto(detailedUserPermissionsEntity);
  }
}
