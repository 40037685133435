import { StateCreator } from 'zustand';
import { UserWithSchoolsDto } from '../../api/dtos/userWithSchools.dto';
import { UserPermissionsDto } from '../../api/dtos/userPermissions.dto';
import { UserDto } from '../../api/dtos/user.dto';

export interface UserSlice {
  userInfo: UserDto;
  setUserInfo: (userInfo: UserDto) => void;

  userWithSchools: UserWithSchoolsDto;
  updateUserWithSchools: (userWithSchools: UserWithSchoolsDto) => void;

  userRolePermissionsForSchool: UserPermissionsDto;
  updateUserRolePermissionsForSchool: (
    userRolePermissionsForSchool: UserPermissionsDto,
  ) => void;

  userStudentPermissionsForSchool: UserPermissionsDto;
  setUserStudentPermissionsForSchool: (
    userPermissionsForStudent: UserPermissionsDto,
  ) => void;
}

export const createUserSlice: StateCreator<UserSlice> = (set, get, api) => ({
  userInfo: UserDto.createDtoFromEntity({
    userId: '',
    email: '',
    name: '',
  }),
  setUserInfo: (userInfo) => set(() => ({ userInfo })),

  userWithSchools: UserWithSchoolsDto.createDtoFromEntity({
    userId: '',
    email: '',
    schools: [],
    eligibleSchoolIds: [],
    hasCompletedStudentsOnboardingTour: false,
  }),
  updateUserWithSchools: (userWithSchools) => set(() => ({ userWithSchools })),

  userRolePermissionsForSchool: UserPermissionsDto.createDtoFromEntity({
    resourceTypePermissions: [],
  }),
  updateUserRolePermissionsForSchool: (userRolePermissionsForSchool) =>
    set(() => ({ userRolePermissionsForSchool })),

  userStudentPermissionsForSchool: UserPermissionsDto.createDtoFromEntity({
    resourceTypePermissions: [],
  }),
  setUserStudentPermissionsForSchool: (userStudentPermissionsForSchool) =>
    set(() => ({ userStudentPermissionsForSchool })),
});
