import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  Box,
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import {
  useGetStudentAssessmentFileDetails,
  useUpdateStudentAssessmentDetails,
  useUploadStudentAssessment,
} from '../../api/services/studentAssessment.service';
import { useParams } from 'react-router-dom';
import { useClientStore } from '../../store/store';
import { DateUtilites } from '../../lib/intellitierDayJs';
import { useForm } from 'react-hook-form';
import DateTimeFormField from '../../components/DateTimeFormField';
import { useGetEntityConfigForSchoolId } from '../../api/services/entityConfig.service';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { useGetPossibleValuesFromEntityConfig } from '../../store/entityConfigHelpers';
import AutocompleteFormField from '../../components/AutocompleteFormField';
import { useGetFormConfigByMode } from '../../hooks/useFormMode';
import BackOrCancelFormButton from '../../components/BackOrCancelFormButton';

interface StudentAssessmentFormProps {
  mode: 'add' | 'edit' | 'view';
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

export const StudentAssessmentForm: React.FC<StudentAssessmentFormProps> = ({
  mode,
}) => {
  const todaysDate = DateUtilites.createDateInLocalDateTime();
  const selectedSchoolId = useClientStore.use.selectedSchoolId();
  const { studentId = '', studentAssessmentId = '' } = useParams();
  const navigate = useViewNavigate();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      fileName: '',
      assessmentType: [] as string[],
      assessmentDate: todaysDate,
    },
  });
  const { shouldDisableForm } = useGetFormConfigByMode(mode);

  useGetEntityConfigForSchoolId(selectedSchoolId);

  const assessmentTypeOptions = useGetPossibleValuesFromEntityConfig(
    'StudentAssessmentDTO',
    'assessmentType',
  );

  const { mutate: uploadAssessment, isPending: isUploading } =
    useUploadStudentAssessment(studentId, selectedSchoolId, () =>
      navigate(`/students/${studentId}/history`),
    );

  const { mutate: updateAssessment, isPending: isUpdating } =
    useUpdateStudentAssessmentDetails(studentId, selectedSchoolId, () =>
      navigate(`/students/${studentId}/history`),
    );

  const {
    data: assessmentDetails,
    isSuccess,
    isLoading: isLoadingDetails,
  } = useGetStudentAssessmentFileDetails(
    studentId,
    selectedSchoolId,
    studentAssessmentId,
  );

  useEffect(() => {
    if (mode !== 'add' && isSuccess && assessmentDetails) {
      reset({
        assessmentType: assessmentDetails.assetType,
        assessmentDate: assessmentDetails.assetDate,
        fileName: assessmentDetails.fileName,
      });
    }
  }, [mode, isSuccess, assessmentDetails, reset]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Additional validations
      const allowedExtensions = [
        'csv',
        'doc',
        'docx',
        'pdf',
        'txt',
        'tsv',
        'xls',
        'xlsx',
        'md',
      ];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        setError(
          'Invalid file type. Allowed types are: csv, doc, docx, pdf, txt, tsv, xls, xlsx, md.',
        );
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds the maximum limit of 5MB.');
        return;
      }

      setSelectedFile(file);
      setValue('fileName', file.name);
      setError(null);
    }
  };
  const handleSubmit = () => {
    const inputFileName = getValues('fileName');
    if (!inputFileName) {
      setError('Please select a file to upload.');
      return;
    }
    if (!inputFileName || !inputFileName.includes('.')) {
      setError('File name must include a valid file extension.');
      return;
    }
    const selectedDate = getValues('assessmentDate');
    const selectedType = getValues('assessmentType');

    // Additional validations
    if (!selectedType || selectedType.length === 0) {
      setError('Please select at least one assessment type.');
      return;
    }

    if (!selectedDate) {
      setError('Please select a valid assessment date.');
      return;
    }

    if (selectedFile) {
      // if users select a file, even if its an update to an assessment, still use the upload endpoint
      uploadAssessment({
        fileUploadDetails: {
          assetId: assessmentDetails?.assetId,
          file: selectedFile,
          assetType: selectedType,
          assetName: inputFileName,
          assetDate: DateUtilites.formatDateToLocalDateTime(
            selectedDate || todaysDate,
          ),
        },
        forceUpload: true,
      });
    } else if (assessmentDetails) {
      updateAssessment({
        fileDetails: {
          assetId: assessmentDetails.assetId,
          fileName: inputFileName,
          assetType: selectedType,
          assetName: inputFileName,
          assetDate: selectedDate,
          aiAnalysisState: assessmentDetails.aiAnalysisState,
          uploadedBy: assessmentDetails.uploadedBy,
        },
      });
    } else {
      setError('Failed to retrieve assessment details.');
    }

    setError(null);
  };

  const getCardTitle = () => {
    switch (mode) {
      case 'add':
        return 'Add Student Assessment Document';
      case 'edit':
        return 'Edit Student Assessment Document';
      case 'view':
        return 'View Student Assessment Document';
      default:
        return '';
    }
  };

  return (
    <Card elevation={3} sx={{ padding: 2, maxWidth: 600, margin: 'auto' }}>
      {isLoadingDetails ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 400,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <CardHeader title={getCardTitle()} />
          <CardContent>
            {(mode === 'add' || mode === 'edit') && (
              <Button
                variant="outlined"
                component="label"
                startIcon={<UploadFile />}
                disabled={shouldDisableForm}
              >
                {selectedFile ? selectedFile.name : 'Upload File'}
                <input
                  type="file"
                  hidden
                  accept=".csv, .doc, .docx, .pdf, .txt, .tsv, .xls, .xlsx, .md"
                  onChange={handleFileChange}
                />
              </Button>
            )}

            {mode !== 'add' && !selectedFile && (
              <TextField
                label="Current File"
                value={getValues('fileName') || ''}
                fullWidth
                margin="normal"
                disabled
              />
            )}

            <AutocompleteFormField
              name="assessmentType"
              label="Assessment Type"
              options={assessmentTypeOptions}
              control={control}
              disabled={shouldDisableForm}
              multiple
            />

            <DateTimeFormField
              name="assessmentDate"
              control={control}
              label="Assessment Date"
              disabled={shouldDisableForm}
            />

            {error && (
              <Typography color="error" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <BackOrCancelFormButton mode={mode} />
            {mode !== 'view' && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isUpdating || isUploading}
              >
                {isUpdating || isUploading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Submit'
                )}
              </Button>
            )}
          </CardActions>
        </form>
      )}
    </Card>
  );
};
