import { StateCreator } from 'zustand';

export interface ThemeSlice {
  selectedThemeName: 'light' | 'dark';
  updateSelectedThemeName: (theme: 'light' | 'dark') => void;
}

export const createThemeSlice: StateCreator<ThemeSlice> = (set, get, api) => ({
  selectedThemeName: 'light',
  updateSelectedThemeName: (selectedThemeName: 'light' | 'dark') =>
    set(() => ({ selectedThemeName })),
});
