import { ResponsivePageContainer } from '../components/ResponsivePageContainer';
import { StudentAssessmentForm } from '../features/Student/StudentAssessmentForm.component';

export type AddOrEditStudentAssessmentPageMode = 'add' | 'view' | 'edit';

const AddOrEditStudentAssessmentPage = ({
  mode,
}: {
  mode: AddOrEditStudentAssessmentPageMode;
}) => {
  return (
    <ResponsivePageContainer>
      <StudentAssessmentForm mode={mode} />
    </ResponsivePageContainer>
  );
};

export default AddOrEditStudentAssessmentPage;
