import { PermissionsDto } from '../api/dtos/permissions.dto';

export const PERMISSIONS = {
  READ: 'canRead' as keyof PermissionsDto,
  WRITE: 'canWrite' as keyof PermissionsDto,
  EDIT: 'canEdit' as keyof PermissionsDto,
  DELETE: 'canDelete' as keyof PermissionsDto,
};

export const ALL_ENABLED_PERMISSIONS = [
  'canRead',
  'canWrite',
  'canEdit',
  'canDelete',
] as Array<keyof PermissionsDto>;
