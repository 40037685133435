import { SchoolDto, SchoolEntity } from './school.dto';

export interface UserWithSchoolsEntity {
  userId: string;
  email: string;
  schools: SchoolEntity[];
  eligibleSchoolIds: string[];
  hasCompletedStudentsOnboardingTour: boolean;
}

export interface UserWithSchoolsDto {
  userId: string;
  email: string;
  schools: SchoolDto[];
  eligibleSchoolIds: string[];
  hasCompletedStudentsOnboardingTour: boolean;
}
export class UserWithSchoolsDto {
  constructor(userWithSchoolsEntity: UserWithSchoolsEntity) {
    this.userId = userWithSchoolsEntity.userId;
    this.email = userWithSchoolsEntity.email;
    this.schools = userWithSchoolsEntity.schools.map((school) =>
      SchoolDto.createDtoFromEntity(school),
    );
    this.eligibleSchoolIds = userWithSchoolsEntity.eligibleSchoolIds;
    this.hasCompletedStudentsOnboardingTour =
      userWithSchoolsEntity.hasCompletedStudentsOnboardingTour;
  }

  static createDtoFromEntity(
    userWithSchoolsEntity: UserWithSchoolsEntity,
  ): UserWithSchoolsDto {
    return new UserWithSchoolsDto(userWithSchoolsEntity);
  }
}
