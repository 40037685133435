type ConversationData = {
  conversationId: string;
  timestamp: number;
};

const CONVERSATION_KEY_EXPIRATION_TIME = 90 * 60 * 1000; // 90 minutes in milliseconds

// GENERIC FUNCTIONS
export const setToLocalStorage = <T>(key: string, value: T): void => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(key: string): T | null => {
  const value = window.localStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return null;
};

// SELECTED SCHOOL ID
export const getSelectedSchoolIdFromLocalStorage = (): string | null => {
  try {
    return getFromLocalStorage<string>('selectedSchoolId');
  } catch (e) {
    const storedSelectedSchoolId = localStorage.getItem('selectedSchoolId');

    if (storedSelectedSchoolId !== null) {
      storeSelectedSchoolIdInLocalStorage(storedSelectedSchoolId);
      return storedSelectedSchoolId;
    }

    return null;
  }
};

export const storeSelectedSchoolIdInLocalStorage = (schoolId: string): void => {
  setToLocalStorage('selectedSchoolId', schoolId);
};

// CHAT WINDOW
export const storeConversationId = (
  key: string,
  conversationId: string,
): void => {
  const timestamp = new Date().getTime();
  const data: ConversationData = { conversationId, timestamp };
  setToLocalStorage<ConversationData>(key, data);
};

export const getConversationId = (key: string): string | null => {
  try {
    const data = getFromLocalStorage<ConversationData>(key);
    if (data) {
      const { conversationId, timestamp } = data;
      const currentTime = new Date().getTime();
      if (currentTime - timestamp < CONVERSATION_KEY_EXPIRATION_TIME) {
        return conversationId;
      } else {
        window.localStorage.removeItem(key);
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const clearExpiredConversationKeys = (): void => {
  const currentTime = new Date().getTime();
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key) {
      try {
        const data = getFromLocalStorage<ConversationData>(key);
        if (data && data.timestamp) {
          if (
            currentTime - data.timestamp >=
            CONVERSATION_KEY_EXPIRATION_TIME
          ) {
            window.localStorage.removeItem(key);
          }
        }
      } catch (error) {
        // Optional: Add logging if needed
      }
    }
  }
};

export const createSchoolConversationIdKey = (schoolId: string): string => {
  return `school-chat-convo-${schoolId}`;
};

export const createStudentConversationIdKey = (studentId: string): string => {
  return `student-chat-convo-${studentId}`;
};

export const clearConversationId = (key: string): void => {
  window.localStorage.removeItem(key);
};
