import { useState } from 'react';
import { ResponsivePageContainer } from '../components/ResponsivePageContainer';
import SelectedStudentsTable from '../components/SelectedStudentsTable';
import { BatchAddBehavioralEventsForm } from '../features/BatchAddBehavioralEvent/BatchAddBehavioralEventForm.component';
import {
  StudentForAutocomplete,
  StudentSearchForAccess,
} from '../features/UserPermissions/StudentSearchForAccess.component';

const BatchAddBehavioralEventPage = () => {
  const [selectedStudents, setSelectedStudents] = useState<
    StudentForAutocomplete[]
  >([]);

  const handleStudentSelect = (
    studentId: string,
    student: StudentForAutocomplete | null,
  ) => {
    if (student && !selectedStudents.find((s) => s.id === studentId)) {
      setSelectedStudents((prevStudents) => [...prevStudents, student]);
    }
  };

  const handleRemoveStudent = (studentId: string) => {
    setSelectedStudents((prevStudents) =>
      prevStudents.filter((student) => student.id !== studentId),
    );
  };

  return (
    <ResponsivePageContainer>
      <StudentSearchForAccess onChangeHandler={handleStudentSelect} />

      {selectedStudents.length > 0 && (
        <SelectedStudentsTable
          selectedStudents={selectedStudents}
          onRemoveStudent={handleRemoveStudent}
        />
      )}

      <BatchAddBehavioralEventsForm
        selectedStudentsFromAutocomplete={selectedStudents}
      />
    </ResponsivePageContainer>
  );
};

export default BatchAddBehavioralEventPage;
