import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useEventCallback,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useClientStore } from '../../store/store';
import { STUDENTS_ROUTE } from '../../constants/routes';
import { useViewNavigate } from '../../hooks/useViewNavigate';
import { usePrefetchGetEntityConfigForSchoolId } from '../../api/services/entityConfig.service';
import { useGetPermissionsForUserInSchool } from '../../api/services/permission.service';
import { useGetUserWithSchools } from '../../api/services/user.service';
import {
  getSelectedSchoolIdFromLocalStorage,
  storeSelectedSchoolIdInLocalStorage,
} from '../../utils/localStorage';
import { UserWithSchoolsDto } from '../../api/dtos/userWithSchools.dto';
import { SchoolDto } from '../../api/dtos/school.dto';

const selectStyles = {
  color: 'white',
  borderColor: 'white',
};

const formControlStyles = {
  borderColor: 'white',
};

export const SchoolSelect: React.FC = () => {
  const {
    selectedSchoolId,
    updateSelectedSchoolId,
    updateUserWithSchools,
    updateUserRolePermissionsForSchool,
    updateSelectedSchool,
  } = useClientStore();

  const viewNavigate = useViewNavigate();
  const location = useLocation();

  const { isLoading, data: userWithSchools } = useGetUserWithSchools();
  const { data: userPermissionsForSchool } =
    useGetPermissionsForUserInSchool(selectedSchoolId);

  usePrefetchGetEntityConfigForSchoolId(selectedSchoolId);

  // Effect to initialize the user's school
  useEffect(() => {
    if (!userWithSchools) return;

    updateUserWithSchools(userWithSchools);

    const storedOrDefaultSchoolId =
      getSelectedSchoolIdFromLocalStorage() ||
      userWithSchools.schools[0].schoolId;

    updateSelectedSchoolId(storedOrDefaultSchoolId);
    storeSelectedSchoolIdInLocalStorage(storedOrDefaultSchoolId);

    findAndUpdateSelectedSchoolById(
      userWithSchools,
      storedOrDefaultSchoolId,
      updateSelectedSchool,
    );
  }, [
    userWithSchools,
    updateSelectedSchoolId,
    updateSelectedSchool,
    updateUserWithSchools,
  ]);

  // Effect to update user permissions for the selected school
  useEffect(() => {
    if (userPermissionsForSchool) {
      updateUserRolePermissionsForSchool(userPermissionsForSchool);
    }
  }, [userPermissionsForSchool, updateUserRolePermissionsForSchool]);

  const handleChange = useEventCallback((event: SelectChangeEvent) => {
    const schoolId = event.target.value as string;
    updateSelectedSchoolId(schoolId);
    storeSelectedSchoolIdInLocalStorage(schoolId);

    findAndUpdateSelectedSchoolById(
      userWithSchools,
      schoolId,
      updateSelectedSchool,
    );

    if (location.pathname.includes('student')) {
      viewNavigate(STUDENTS_ROUTE);
    }
  });

  const memoizedSchools = useMemo(() => {
    return userWithSchools?.schools.map((school) => (
      <MenuItem key={school.schoolId} value={school.schoolId}>
        {school.schoolName}
      </MenuItem>
    ));
  }, [userWithSchools]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormControl sx={formControlStyles}>
      <Select
        autoWidth
        displayEmpty
        labelId="school-select-label"
        id="school-select"
        value={selectedSchoolId}
        onChange={handleChange}
        variant="outlined"
        sx={selectStyles}
      >
        {memoizedSchools}
      </Select>
    </FormControl>
  );
};

function findAndUpdateSelectedSchoolById(
  userWithSchools: UserWithSchoolsDto | undefined,
  schoolId: string,
  updateSelectedSchool: (selectedSchool: SchoolDto) => void,
) {
  const selectedSchool = userWithSchools?.schools.find(
    (school) => school.schoolId === schoolId,
  );
  if (selectedSchool) {
    updateSelectedSchool(selectedSchool);
  }
}
